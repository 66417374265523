import axios from "axios";

axios.defaults.baseURL = 'https://bizlx.itechvision.in/api/';
// axios.defaults.baseURL = 'http://localhost:8000/api/';
axios.mediaURL = 'https://bizlx.itechvision.in';

// const token = localStorage.getItem('token');
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('userToken');
// if (token) {
//   axios.defaults.headers.common['Authorization'] = token;
// }