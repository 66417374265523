<template>
  <div>

    <v-form @submit.prevent="ClickSearch" class="catsearch d-flex mobile bg-white">

      <v-autocomplete dense hide-details placeholder="Category"
          v-model="selectSubCategoty" 
          :items="categories" 
          item-value="id" 
          item-text="subcat_name" :no-data-text="noCat"
          id="sel1" class="sel1 cat1 "
          @keyup.native="fetchCatm">
      </v-autocomplete>

      <v-autocomplete dense hide-details placeholder="Location"
          v-model="selectCity" 
          :items="allCities" 
          item-value="city_id"
          item-text="city" :no-data-text="noCity"
          id="sel1" class="sel1 loc1"
          @keyup.native="fetchLocm">
      </v-autocomplete>

      <v-autocomplete dense hide-details placeholder="Deals" 
          v-model="deal"
          :items="deals" id="sel1" class="sel1 deal1"
          >
      </v-autocomplete>

      <v-btn type="submit" max-width="36px" min-width="36px" color="cred"><v-icon class="text-white col-1">mdi-magnify</v-icon></v-btn>
    </v-form>

    <v-form @submit.prevent="ClickSearch" class="catsearch d-flex desktop">

        <v-autocomplete dense rounded hide-details placeholder="Category"
            v-model="selectSubCategoty"
            :items="categories"
            item-value="id" 
            item-text="subcat_name" :no-data-text="noCat"
            id="sel2" class="sel2" prepend-inner-icon="mdi-format-list-bulleted-square"
            @keyup.native="fetchCat">
        </v-autocomplete>

        <v-autocomplete dense rounded hide-details placeholder="Location" 
            v-model="selectCity" 
            :items="allCities" 
            item-value="city_id" 
            item-text="city" :no-data-text="noCity"
            id="sel2" class="sel2" prepend-inner-icon="mdi-map-marker"
            @keyup.native="fetchLoc">
        </v-autocomplete>

        <v-autocomplete dense rounded hide-details placeholder="Deals" 
            v-model="deal"
            :items="deals" id="sel2" class="sel2" prepend-inner-icon="mdi-checkbox-marked-outline"
            >
        </v-autocomplete>

        <v-btn type="submit" color="cred"><v-icon class="text-white">mdi-magnify</v-icon>Search</v-btn>
    </v-form>
    

    <v-snackbar v-model="snackbar" :timeout="snackbar.timeout" class="snackbar-shadow srch">{{ text }}
        <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">OK</v-btn>
        </template>
    </v-snackbar>


  </div>
</template>

<script>

import axios from 'axios';
import { EventBus } from "@/event-bus";
export default {
  name: "CatSearch",
  
  data: () => ({
      selectSubCategoty: null,
      subname:'',
      catids:[],
      selectCity: null,
      searchcat:'',
      searchcatm:'',
      searchloc:'',
      searchlocm:'',
      isLoadingcat:false,
      isLoadingcatm:false,
      isLoadingloc:false,
      isLoadinglocm:false,
      noCat:'Select Category',
      noCity:'Select Location',
      deal: '',
      categories: [],
      categoriesm: [],
      locations:[],
      allCities: [],
      allCitiesm: [],
      deals:['Hot Deals', 'Sales','Jobs', 'Videos'],
      snackbar: '',
      text: '',
      timeout: 3000,
      loggedUser: []
  }),
  // computed:{
  //   filterCats() {
  //     return this.categories.filter(item => item.subcat_name.toLowerCase().includes(this.searchcat.toLowerCase()));
  //   },
  //   filterCatsm() {
  //     return this.categoriesm.filter(item => item.subcat_name.toLowerCase().includes(this.searchcatm.toLowerCase()));
  //   },
  //   filterLocs() {
  //     return this.allCities.filter(item => item.city.toLowerCase().includes(this.searchloc.toLowerCase()));
  //   },
  //   filterLocsm() {
  //     return this.allCitiesm.filter(item => item.city.toLowerCase().includes(this.searchlocm.toLowerCase()));
  //   }
  // },
  // watch:{
  //   searchcat(newcat) {
  //     if (newcat.length >= 3) {
  //       this.fetchCat(newcat);
  //     } else {
  //       this.categories = [];
  //       this.isLoadingcat = false;
  //     }
  //   },
  //   searchcatm(newcatm) {
  //     if (newcatm.length >= 3) {
  //       this.fetchCatm(newcatm);
  //     } else {
  //       this.categoriesm = [];
  //       this.isLoadingcatm = false;
  //     }
  //   },
  //   searchloc(newLoc) {
  //     if (newLoc.length >= 3) {
  //       this.fetchLoc(newLoc);
  //     } else {
  //       this.allCities = [];
  //       this.isLoadingloc = false;
  //     }
  //   },
  //   searchlocm(newLocm) {
  //     if (newLocm.length >= 3) {
  //       this.fetchLocm(newLocm);
  //     } else {
  //       this.allCitiesm = [];
  //       this.isLoadinglocm = false;
  //     }
  //   }
  // },
  methods: {
    fetchCat(event) {
      var value = event.target.value.trim(); // Trim to remove leading and trailing spaces
      if (value.length > 2) {
        axios.get(`keyword/catsearch?keyword=${value}`)
            .then((resp) => {
              if (event.target.value.trim().length > 0) {
                this.categories = resp.data.subcategories;
                this.catids = resp.data.subcategories;
                console.log("this.categories");
                console.log(this.categories);
              }else {
                console.log('Input value is now empty, ignoring response data');
              }
          }
        ).catch((error) => {
                console.error('Error fetching subcategories:', error);
        });
      }else {
        // Handle case when value is an empty string
        console.log('Value is an empty string');
        this.categories = []; // Set subcats to empty array or handle it as needed
      }
    },
    fetchCatm(event) {
      var value = event.target.value.trim(); // Trim to remove leading and trailing spaces
      if (value.length > 2) {
        axios.get(`keyword/catsearch?keyword=${value}`)
            .then((resp) => {
              if (event.target.value.trim().length > 0) {
                this.categories = resp.data.subcategories;
              }else {
                console.log('Input value is now empty, ignoring response data');
              }
          }
        ).catch((error) => {
                console.error('Error fetching subcategories:', error);
        });
      }else {
        // Handle case when value is an empty string
        console.log('Value is an empty string');
        this.categories = []; // Set subcats to empty array or handle it as needed
      }
    },
    fetchLoc(event) {
      var value = event.target.value.trim(); // Trim to remove leading and trailing spaces
      if (value.length > 2) {
        axios.get(`keyword/allcities?keyword=${value}`)
            .then((resp) => {
              if (event.target.value.trim().length > 0) {
                this.allCities = resp.data.locations;
              }else {
                console.log('Input value is now empty, ignoring response data');
              }
          }
        ).catch((error) => {
                console.error('Error fetching subcategories:', error);
        });
      }else {
        // Handle case when value is an empty string
        console.log('Value is an empty string');
        this.allCities = []; // Set subcats to empty array or handle it as needed
      }
    },
    fetchLocm(event) {
      var value = event.target.value.trim(); // Trim to remove leading and trailing spaces
      if (value.length > 2) {
        axios.get(`keyword/allcities?keyword=${value}`)
            .then((resp) => {
              if (event.target.value.trim().length > 0) {
                this.allCities = resp.data.locations;
              }else {
                console.log('Input value is now empty, ignoring response data');
              }
          }
        ).catch((error) => {
                console.error('Error fetching subcategories:', error);
        });
      }else {
        // Handle case when value is an empty string
        console.log('Value is an empty string');
        this.allCities = []; // Set subcats to empty array or handle it as needed
      }
    },
    onInput() {
      // Handle the input event if needed
      console.log('Input event triggered');
    },
    ClickSearch(){
          this.loggedUser = this.$store.state.userData;
          if( this.selectSubCategoty!=null || this.selectCity!=null || this.deal!=null ){
            const config = {
              headers: { 'content-type': 'multipart/form-data' }
            }

            let selectedCategoryId = this.selectSubCategoty;
            let matchedCategory = this.categories.find(category => category.id === selectedCategoryId);

            let selectedCity = this.selectCity;
            let matchedCity = this.allCities.find(city => city.city_id === selectedCity);

            let data = { 
                selectSubCategoty: this.selectSubCategoty,
                selectSubCatname: matchedCategory ? matchedCategory.subcat_name : '', // Check if matchedCategory exists
                selectCity: this.selectCity,
                selectCityname: matchedCity ? matchedCity.city : '', // Check if matchedCity exists
                jobcat_name:'',
                deal: this.deal,
                loggedUser_id: this.loggedUser.id,
            };
            console.log("data");
            console.log(data);
            axios.post("search/category", data, config)
                .then((resp) => {
                  localStorage.removeItem('catSearchedValues');
                  localStorage.removeItem('jobSearchedValues');

                  if(resp.data.searchType=="hot deals"){ // deals page

                      if(resp.data.searched_data.length>0){
                        setTimeout(()=>{
                            EventBus.$emit('event_bus_emitted', { // payload with data
                              searched_data:resp.data.searched_data,
                            })
                        },1000);
                        this.$router.push({name: 'searchall',
                        params:{subcat_name:data.selectSubCatname,city_name:data.selectCityname,jobcat_name:data.jobcat_name}
                        });
                        const dealtype = 0;
                        this.$store.dispatch('setdealtypeid',{dealtype});
                        const city_id = data.selectCity;
                        this.$store.dispatch('setcityid',{city_id});
                        const subcat_id = data.selectSubCategoty;
                        this.$store.dispatch('setsubcatid',{subcat_id});
                        localStorage.setItem("catSearchedValues", JSON.stringify(data));
                        
                      }else{
                        this.snackbar = true; 
                        this.text = "Searched data not found.";
                      }

                  }else if(resp.data.searchType=="sales"){ // deals page
                    if(resp.data.searched_data.length>0){
                      setTimeout(()=>{
                          EventBus.$emit('event_bus_emitted', { // payload with data
                            searched_data:resp.data.searched_data,
                          })
                      },1000);
                      this.$router.push({name: 'searchall',params:{subcat_name:data.selectSubCatname,city_name:data.selectCityname,jobcat_name:data.jobcat_name}});
                      const dealtype = 1;
                      this.$store.dispatch('setdealtypeid',{dealtype});
                      const city_id = data.selectCity;
                      this.$store.dispatch('setcityid',{city_id});
                      const subcat_id = data.selectSubCategoty;
                      this.$store.dispatch('setsubcatid',{subcat_id});
                      localStorage.setItem("catSearchedValues", JSON.stringify(data));
                    }else{
                      this.snackbar = true;
                      this.text = "Searched data not found.";
                    }

                  }else if(resp.data.searchType=="jobs"){ // jobs page
                    if(resp.data.searched_data.length>0){
                      setTimeout(()=>{
                          EventBus.$emit('event_bus_emitted', { // payload with data
                            searched_data:resp.data.searched_data,
                          })
                      },1000);
                      // this.$router.push("/jobs/search/page");
                      this.$router.push({name: 'searchall',params:{subcat_name:data.selectSubCatname,city_name:data.selectCityname,jobcat_name:data.jobcat_name}});
                      const dealtype = 2;
                      this.$store.dispatch('setdealtypeid',{dealtype});
                      const city_id = data.selectCity;
                      this.$store.dispatch('setcityid',{city_id});
                      const subcat_id = '';
                      this.$store.dispatch('setsubcatid',{subcat_id});
                      const jobcat_id = '';
                      this.$store.dispatch('setjobcatid',{jobcat_id});
                      localStorage.setItem("catSearchedValues", JSON.stringify(data));
                    }else{
                      this.snackbar = true;
                      this.text = "Searched data not found.";
                    }

                  }else if(resp.data.searchType=="videos"){ // videos page
                    if(resp.data.searched_data.length>0){
                      setTimeout(()=>{
                          EventBus.$emit('event_bus_emitted', { // payload with data
                            searched_data:resp.data.searched_data,
                          })
                      },1000);
                      this.$router.push("/allvideos");
                      localStorage.setItem("catSearchedValues", JSON.stringify(data));
                    }else{
                      this.snackbar = true;
                      this.text = "Searched data not found.";
                    }

                  }else if(resp.data.searchType=="sub-category" || resp.data.searchType=="city"){ // deals page
                      if(resp.data.searched_data.length>0){
                        setTimeout(()=>{
                            EventBus.$emit('event_bus_emitted', { // payload with data
                              searched_data:resp.data.searched_data,
                            })
                        },1000);
                        const dealtype = 3;
                        this.$store.dispatch('setdealtypeid',{dealtype});
                        const city_id = data.selectCity;
                        this.$store.dispatch('setcityid',{city_id});
                        const subcat_id = data.selectSubCategoty;
                        this.$store.dispatch('setsubcatid',{subcat_id});
                        this.$router.push({name:'searchall',params:{subcat_name:data.selectSubCatname,city_name:data.selectCityname,jobcat_name:data.jobcat_name}});
                        localStorage.setItem("catSearchedValues", JSON.stringify(data));
                      }else{
                        this.snackbar = true;
                        this.text = "Searched data not found.";
                      }
                  }
                  this.selectSubCategoty = '';
                  this.selectCity = '';
                  this.deal = '';
                },
            );
          }else{
            this.snackbar = true; 
              this.text = "Please select deal for search.";
          }
      }
  }
}
</script>

<style scoped>
.catsearch.d-flex {
  border: 1px solid;
  border-radius: 4px;
  padding: 0 0 0 4px;
}
.sel2 {
  font-size: 14px;
}
#sel1::placeholder {
  color: #000000 !important;
  opacity: 1;
}
.v-btn {border-radius: 0 4px 4px 0;}
@media(min-width: 576.1px){
  .mobile{display: none !important;}
}
@media(max-width: 576px){
  .desktop{display: none !important;}
  .sel1 {
    font-size: 13px;
  }
}
.catsearch.mobile .cat1 {
  width: 120px !important;
}
.catsearch.mobile .loc1 {
  width: 80px !important;
}
.catsearch.mobile .deal1 {
   width: 80px !important;
 }
</style>