import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {path: '/', name: 'home', component: HomeView},
  {path: '/user/login', name: 'userlogin', component: () => import('../views/user/UserLogin')},
  {path: '/searchall', name: 'searchall', component: () => import('../views/search/SearchAll'),props: true},
  {path: '/user/register', name: 'userregister', component: () => import('../views/user/UserRegister')},

  {path: '/admin/login', name: 'adminlogin', component: () => import('../views/admin/AdminLogin')},

  {path: '/subadmin/login', name: 'subadminlogin', component: () => import('../views/admin/SubAdminLogin')},

  {path: '/forgetpassword', name: 'userforgetpassword', component: () => import('../views/user/UserForgetpassword')},
  {path: '/resetpassword/:email', name: 'userresetpassword', component: () => import('../views/user/UserResetpassword')},

  {path: '/reseller/login', name: 'resellerlogin', component: () => import('../views/reseller/ResellerLogin')},
  {path: '/reseller/register', name: 'resellerregister', component: () => import('../views/reseller/ResellerRegister')},
  {path: '/business/login', name: 'businesslogin', component: () => import('../views/business/BusinessLogin')},
  {path: '/business/register', name: 'businessregister', component: () => import('../views/business/BusinessRegister')},

  {path: '/user', name: 'usersidebar', component: () => import('../views/user/UserSidebar'),
    children: [
      {path: 'profile', name: 'userprofile', component: () => import('../views/user/UserProfile')},
      {path: 'wishlist', name: 'userwishlist', component: () => import('../views/user/UserWishlist')},
      {path: 'notification', name: 'usernotification', component: () => import('../views/user/UserNotification')},
      {path: 'reviews', name: 'userreviews', component: () => import('../views/user/UserReviews')},
    ]},

  {path: '/reseller', name: 'resellersidebar', component: () => import('../views/reseller/ResellerSidebar'),
    children:[
        {path: 'business-list', name: 'resellerbusinesslist', component: () => import('../views/reseller/BusinessList')},
        {path: 'update/business-list/:business_id', name: 'updatebusinesslist', component: () => import('../views/reseller/UpdateBusinesslist'), props: true},
        {path: 'client/new', name: 'newclient', component: () => import('../views/reseller/ClientBusinessNew')},
        {path: 'payment', name: 'resellerpayment', component: () => import('../views/reseller/ResellerPayment')},
        {path: 'profile', name: 'resellerprofile', component: () => import('../views/reseller/ResellerProfile')},
        {path: 'wishlist', name: 'resellerwishlist', component: () => import('../views/reseller/ResellerWishlist')},
        {path: 'reviews', name: 'resellerreviews', component: () => import('../views/reseller/ResellerReviews')},
        {path: 'jobs', name: 'resellerjobs', component: () => import('../views/reseller/ResellerJobs')},
    ]},

  {path: '/business', name: 'businesssidebar', component: () => import('../views/business/BusinessSidebar'),
    children: [
        {path: 'profile', name: 'businessdashboard', component: () => import('../views/business/BusinessProfile')},
        {path: 'main-cover-image', name: 'businesscover', component: () => import('../views/business/BusinessCover')},
        {path: 'gallery-images', name: 'businessgallery', component: () => import('../views/business/BusinessGallery')},
        {path: 'deals', name: 'businesshotdeals', component: () => import('../views/business/BusinessHotdeals')},
        {path: 'billing', name: 'businessbilling', component: () => import('../views/business/BusinessBilling')},
        {path: 'jobs', name: 'businessjobs', component: () => import('../views/business/BusinessJobs')},
        {path: 'wishlist', name: 'businesswishlist', component: () => import('../views/business/BusinessWishlist')},
        {path: 'notification', name: 'businessnotification', component: () => import('../views/business/BusinessNotification')},
        {path: 'reviews', name: 'businessreviews', component: () => import('../views/business/BusinessReviews')},
        {path: 'bybusiness', name: 'bybusiness', component: () => import('../views/business/ReviewBybusiness')},
      ]},

  {path: '/contact', name: 'contact', component: () => import('../views/ContactView')},
  {path: '/about', name: 'about', component: () => import('../views/AboutView')},
  {path: '/payment/plans', name: 'paymentplans', component: () => import('../views/pages/PaymentPlans')},
  {path: '/terms-of-use', name: 'termsuse', component: () => import('../views/pages/TermsUse')},
  {path: '/privacy-policy', name: 'privacypolicy', component: () => import('../views/pages/PrivacyPolicy')},
  {path: '/advancesearch', name: 'advancesearch', component: () => import('../views/AdvanceSearch')},
  {path: '/reviews', name: 'reviews', component: () => import('../views/ReviewView')},
  {path: '/reviewsby/business/:business', name: 'reviewsby', component: () => import('../views/embedcode/ReviewByBusiness'),props: true},
  {path: '/category/all', name: 'categoryall', component: () => import('../views/AllCategories')},
  {path: '/city-listings/:city', name: 'citylistings', component: () => import('../views/CityListings'),props: true},
  {path: '/deals-listings/:city', name: 'citydeals', component: () => import('../views/CityDeals'),props: true},

  {path: '/jobs/all-cities', name: 'allcityjobs', component: () => import('../views/jobs/AllCityjobs')},
  {path: '/jobs/cat-city/:job_cat_slug', name: 'allcatjobs', component: () => import('../views/jobs/AllCatjobs'), props: true},
  {path: '/jobs/category/:city', name: 'allcategory', component: () => import('../views/jobs/Allcategoryjobs'), props: true},
  {path: '/jobsby/business/:business', name: 'jobsbycity', component: () => import('../views/embedcode/ByCity'), props: true},
  {path: '/jobs/category/:job_cat_slug/:city', name: 'jobsbycatcity', component: () => import('../views/jobs/CatcityJobs'), props: true},
  {path: '/jobs/detail/:job_slug', name: 'jobdetail', component: () => import('../views/jobs/JobDetail'), props: true},
  {path: '/jobs/search/page', name: 'jobsearchpage', component: () => import('../views/jobs/JobsearchPage')},
  {path: '/category/search', name: 'searchpageCatCity', component: () => import('../views/search/businessSearch')},

  {path: '/hotdealsby/business/:business', name: 'hotdealsbybusiness', component: () => import('../views/embedcode/HotdealsByBusiness'),props: true},
  {path: '/deals/all', name: 'alldeals', component: () => import('../views/deals/AllDeals')},
  {path: '/deals/daily', name: 'dailydeals', component: () => import('../views/deals/DailyDeals')},
  {path: '/deals/detail/:hotdeal_slug', name: 'dealsdetail', component: () => import('../views/deals/DealDetail'),props: true},

  {path: '/mywishlist', name: 'mywishlist', component: () => import('../views/MyWishlist')},
  {path: '/mynotification', name: 'mynotification', component: () => import('../views/MyNotification')},
  {path: '/:uname', name: 'businessprofile', component: () => import('../views/BusinessView'), props: true},
  {path: '/category/city/search/:city_id/:sub_cat_id', name: 'categorycitysearch', component: () => import('../views/CategorycitySearch'), props: true},
    {path: '/search/deals/:sub_cat/:city_id', name: 'searchDeals', component: () => import('../views/search/SearchDeals'), props: true},

  {path: '/allvideos', name: 'allvideos', component: () => import('../views/videos/AllVideo')},
  {path: '/video/detail/:video_slug', name: 'videodetail', component: () => import('../views/videos/VideoDetail'), props: true},
  {path: '/salesby/business/:business', name: 'salesbybusiness', component: () => import('../views/embedcode/SalesByBusiness'), props: true},
  {path: '/sales/all',name: 'allsales',component: () => import('../views/sales/SalesAll')},
  {path: '/sales/detail/:sale_slug',name: 'saledetail',component: () => import('../views/sales/SaleDetail'),props: true},

  {path:'/subadmin/dashboard', name: 'subdashboard', component: () => import('../views/admin/DashBoard.vue')},
  {path: '/admin', name: 'admin', component: () => import('../views/admin/AdminView.vue'),
    children: [
      {path: 'dashboard', name: 'dashboard', component: () => import('../views/admin/DashBoard.vue')},
      {path: 'users', name: 'users', component: () => import('../views/admin/UsersView.vue')},
      {path: 'reviews', name: 'adminreviews', component: () => import('../views/admin/ReviewsView.vue')},
      {path: 'reports', name: 'reports', component: () => import('../views/admin/DealReports.vue')},
      {path: 'subadmins', name: 'subadmins', component: () => import('../views/admin/SubAdmins.vue')},
      {path: 'subadmins/new', name: 'subadminsnew', component: () => import('../views/admin/SubadminsNew.vue')},
      {path: 'resellers', name: 'resellers', component: () => import('../views/admin/ResellersView.vue')},
      {path: 'resellers/new', name: 'resellersnew', component: () => import('../views/admin/ResellersNew.vue')},
      {path: 'resellers/update/:id', name: 'resellersupdate', component: () => import('../views/admin/ResellersUpdate.vue'), props: true},
      {path: 'businesslist', name: 'businesslist', component: () => import('../views/admin/BusinessList.vue')},
      {path: 'businesslist/update/:id', name: 'businesslistupdate', component: () => import('../views/admin/BusinesslistUpdate.vue'), props: true},
      {path: 'maincategories', name: 'maincategories', component: () => import('../views/admin/MainCategories.vue')},
      {path: 'maincategories/new', name: 'maincategoriesnew', component: () => import('../views/admin/CreateCategory.vue')},
      {path: 'subcategories', name: 'subcategories', component: () => import('../views/admin/SubCategories.vue')},
      {path: 'subcategories/new', name: 'subcategoriesnew', component: () => import('../views/admin/CreateSubcategory.vue')},
      {path: 'tabs', name: 'tabs', component: () => import('../views/admin/TabsView.vue')},
      {path: 'tabs/new', name: 'tabsnew', component: () => import('../views/admin/CreateTabs.vue')},
      {path: 'payments', name: 'payments', component: () => import('../views/admin/PaymentsView.vue')},
      {path: 'paymentoffer', name: 'paymentoffer', component: () => import('../views/admin/PaymentOffer.vue')},
      {path: 'registration/image', name: 'registrationimage', component: () => import('../views/admin/RegistrationImage.vue')},
      {path: 'contact/phone', name: 'contactphone', component: () => import('../views/admin/ContactPhone.vue')},
      {path: 'city', name: 'city', component: () => import('../views/admin/CityView.vue')},
      {path: 'city/new', name: 'citynew', component: () => import('../views/admin/CreateCity.vue')},
      {path: 'state', name: 'state', component: () => import('../views/admin/StateView.vue')},
      {path: 'country', name: 'country', component: () => import('../views/admin/CountryView.vue')},
      {path: 'country/create', name: 'countrynew', component: () => import('../views/admin/CreateCountry.vue')},
      {path: 'state/new', name: 'statenew', component: () => import('../views/admin/CreateState.vue')},
      {path: 'pages', name: 'pages', component: () => import('../views/admin/PagesView.vue')},
      {path: 'pages/new', name: 'pagesnew', component: () => import('../views/admin/CreatePages.vue')},
      {path: 'pages/edit/:page_id', name: 'pagesedit', component: () => import('../views/admin/EditPage.vue'), props: true},
      {path: 'pagecontent', name: 'pagecontent', component: () => import('../views/admin/PageContent.vue')},
      {path: 'profile', name: 'profile', component: () => import('../views/admin/ProfileView.vue')},
      {path: 'branding', name: 'branding', component: () => import('../views/admin/BrandingView.vue')},
      {path: 'plans', name: 'plans', component: () => import('../views/admin/PlansView.vue')},
      {path: 'new/plans', name: 'newplans', component: () => import('../views/admin/CreateNewplan.vue')},
      {path: 'update/plans/:plan_id', name: 'updateplans', component: () => import('../views/admin/UpdatePlans.vue'), props: true},
      {path: 'payment/commission', name: 'paymentcommission', component: () => import('../views/admin/PaymentCommission.vue')},
      {path: 'citydeal', name: 'citydeal', component: () => import('../views/admin/CityDeal.vue')},
      {path: 'category/slider', name: 'categoryslider', component: () => import('../views/admin/CategorySlider.vue')},
      {path: 'category/banner', name: 'categorybanner', component: () => import('../views/admin/CategoryBanner.vue')},
      {path: 'ads', name: 'ads', component: () => import('../views/admin/AdsView.vue')},
      {path: 'jobcategories', name: 'jobcategories', component: () => import('../views/admin/JobCategory')},
      {path: 'jobqualification', name: 'jobqualification', component: () => import('../views/admin/JobQualification')},
      {path: 'jobtypes', name: 'jobtypes', component: () => import('../views/admin/JobTypes')},
    ]},
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
