<template>
  <div>
    <carousel v-if="subcats.length > 0" :autoplay="false" :margin="10" :dots="false" :slideBy="2" :loop="true"
              :navText="['‹','›']"
              :responsive = "{0:{items:4},576:{items:4},768:{items:4},1200:{items:8}}"
              class="cats mobile">
      <div v-for="(group, i) in subcatGroups" :key="i">
        <div class="item" v-for="cat in subcats.slice(i * itemsPerRow3, (i + 1) * itemsPerRow3)" :key="cat.id" @click="handleItemClick(cat)">
          <div v-b-modal.model-city>
            <img :src=(url+cat.subcat_img_url) width="40" height="40" :alt=cat.subcat_img_url>
            <p class="category small">{{cat.subcat_name}}</p>
          </div>
        </div>
      </div>
    </carousel>

    <carousel v-if="subcats.length > 0"  :margin="20" :dots="false" :slideBy="2" :loop="true"
              :navText="['‹','›']"
              :responsive = "{0:{items:4},576:{items:4},768:{items:5},992:{items:6},1200:{items:8}}"
              class="cats desktop">
      <div v-for="(group, i) in catGroups" :key="i">
        <div class="item" v-for="cat in subcats.slice(i * itemsPerRow, (i + 1) * itemsPerRow)" :key="cat.id" @click="handleItemClick(cat)">
          <div v-b-modal.model-city>
            <img :src=(url+cat.subcat_img_url) width="40" height="40" :alt=cat.subcat_img_url>
            <p class="category small">{{cat.subcat_name}}</p>
          </div>
        </div>
      </div>
    </carousel>

    <b-modal id="model-city" :title=subCatName hide-footer modal-class="popmodal mr112">
      <v-form ref="form" @submit.prevent="populareCatSearche">
        <v-text-field type="text" v-model="subCatName" style="display: none;"></v-text-field>
        <v-text-field type="text" v-model="subCat_id" style="display: none;"></v-text-field>
        <v-autocomplete label="Select City"
            v-model="selectCity"
            :items="filteredCities" 
            item-value="city_id"
            item-text="city"></v-autocomplete>
        <div class="mt-3 text-center">
            <b-button variant="primary" type="submit">Submit</b-button>
        </div>
      </v-form>

      <v-snackbar v-model="snackbar" :timeout="snackbar.timeout" class="snackbar-shadow">{{ text }}
        <template v-slot:action="{ attrs }">
            <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">OK</v-btn>
        </template>
    </v-snackbar>



    </b-modal>
    

  </div>
</template>

<script>
import carousel from "vue-owl-carousel";
import axios from "axios";

export default {
  name: "HomePopulars",
  components:{carousel},
  data (){
    return{
      url: 'https://bizlx.itechvision.in',
      dialog:false,
      itemsPerRow:2,
      itemsPerRow3:3,
      subcats: [],
      selectCity: null,
      filteredCities: [],
      subCatName: '',
      subCat_id: '',

      snackbar: '',
      text: '',
      timeout: 3000,
    }
  },
  mounted() {
    this.getSubcategory();
  },
  methods:{
    getSubcategory(){
      axios.get('populars')
          .then((resp)=>{
            this.subcats = resp.data.populars;
        });
    },
    handleItemClick(cat) {
      this.subCatName = cat.subcat_name;
      this.subCat_id = cat.id;
      this.filteredCities = [];
      axios.get("popular/cat/cities/"+cat.id)
          .then((resp) => {
              this.filteredCities = resp.data.popularCatCities;
          }
      )
    },
    populareCatSearche() {
      let selectedCity = this.selectCity;
      let matchedCity = this.filteredCities.find(city => city.city_id === selectedCity);
        if(this.selectCity==null ){ // /category/city/search
            this.snackbar = true; 
            this.text = "Please select city.";
        }else{
            this.$router.push({
                name: 'searchall',
                params: {
                    city_id: this.subCat_id,
                    sub_cat_id: this.selectCity,
                    subcat_name: this.subCatName,
                    city_name: matchedCity.city,
                }
            });
          const dealtype = 3;
          this.$store.dispatch('setdealtypeid',{dealtype});
          const city_id = this.selectCity;
          this.$store.dispatch('setcityid',{city_id});
          const subcat_id = this.subCat_id;
          this.$store.dispatch('setsubcatid',{subcat_id});
        }
    },
    
  },


  computed: {
    catGroups () {
      return Array.from(Array(Math.ceil(this.subcats.length / this.itemsPerRow)).keys())
    },
    subcatGroups () {
      return Array.from(Array(Math.ceil(this.subcats.length / this.itemsPerRow3)).keys())
    },
  },

}
</script>
<style scoped>
div.cats img {
  width: auto;
  margin: 0 auto;
}
div.cats p.category {
  text-align: center;
  height: 42px;
  overflow: hidden;
  margin-bottom: 0;
}
@media (max-width: 576px){
  div.cats p.category {
    text-align: center;
    height: 36px;
    overflow: hidden;
    font-size: 12px;
  }
  .desktop{display: none;}
}
@media (min-width: 576.1px) {
  .mobile{display: none;}
}
</style>