<template>
  <div>
    <div class="container">
      <div class="desktop row pb-3" v-for="cats in catslider" :key="cats.id">
        <div class="hometabs px-3 px-md-0">
          <h5 class="home-service cblu mb-3">
            <span class="bbred">{{cats.subcat_name}}</span>
          </h5>
        </div>
        <b-tabs lazy content-class="p-0" class="px-0" pills active-nav-item-class="fw-bold bgred mb-2 text-white"
                nav-class="fw-bold mtab bootabs">
          <b-tab title="Info" active>
              <b-card-text>
                <v-card flat>
                  <v-card-text class="p-0">
                    <div v-if="cats.info.length > 0">
                      <carousel :autoplay="false" :nav="true"
                                :margin="30" :items="4" :dots="false" :slideBy="1" :navText="['‹','›']"
                                :responsive = "{0:{items:1},576:{items:2},768:{items:2.3},992:{items:3},1200:{items:4}}" class="hotels desktop">
                        <div v-for="hotel in cats.info" :key="hotel.id" class="item">
                            <v-card>
                              <router-link :to="{name: 'businessprofile', params: {id: hotel.business_id, uname:hotel.business_uname}}" class="link-dark text-decoration-none">
                                  <div v-if="hotel.mainimage != null">
                                    <v-img cover :src=(url+hotel.mainimage) max-height="150" :aspect-ratio="16/9"></v-img>
                                  </div>
                                  <div v-else>
                                    <v-img cover :src="('https://dummyimage.com/299x150/969096/3f4042.jpg&text='+hotel.first_last_letter)" height="150"></v-img>
                                  </div>
                              </router-link>
                              <router-link class="mt-2 p-2 d-flex link-dark" :to="{name: 'businessprofile', params: {id: hotel.business_id, uname:hotel.business_uname}}" >
                                 <div class="lt w-100">
                                   <router-link :to="{name: 'businessprofile', params: {id: hotel.business_id, uname:hotel.business_uname}}" class="link-dark text-decoration-none">
                                     <h6 style="line-height:22px;max-height: 22px;overflow: hidden;">{{ hotel.name }}</h6>
                                   </router-link>
                                   <div class="mreviews d-flex align-items-center">
                                     <v-rating dense color="warning" size="15" length="5" :value=hotel.rating readonly></v-rating><span class="badge bg-dark ms-2">{{ hotel.rating }}</span>
                                   </div>
                                   <div style="min-height: 44px">
                                     <v-icon small>mdi-map-marker</v-icon>
                                     {{hotel.city}}, {{hotel.state}}
                                   </div>
                                 </div>
                                <div class="mcontact">
                                  <a :href="('https://wa.me/'+hotel.mobile_phone+'?text=Enquiry from Bizlx : '+currentURL)" target="_blank">
                                    <v-btn fab x-small color="success">
                                      <v-icon>mdi-whatsapp</v-icon>
                                    </v-btn>
                                  </a>
                                  <a :href="('tel:'+ hotel.mobile_phone)" target="_blank">
                                    <v-btn fab x-small color="primary" class="my-2">
                                      <v-icon>mdi-phone</v-icon>
                                    </v-btn>
                                  </a>
                                  <router-link :to="{name: 'businessprofile', params: {id: hotel.business_id, uname:hotel.business_uname}}">
                                    <v-btn fab x-small color="info">
                                      <v-icon>mdi-eye-outline</v-icon></v-btn>
                                  </router-link>
                                </div>
                              </router-link>
                            </v-card>
                        </div>
                      </carousel>
                    </div>
                    <div v-else class="text-center">
                      <div>No Business in {{cats.subcat_name}}</div>
                    </div>
                  </v-card-text>
                </v-card>
              </b-card-text>
            </b-tab>
          <b-tab title="Hot Deals">
              <b-card-text>
                <v-card flat>
                  <v-card-text class="p-0">
                    <div v-if="cats.hotdeal.length > 0">
                       <carousel :autoplay="false" :margin="30" :dots="false" :navText="['‹', '›']" :slideBy="1"
                              :responsive = "{0:{items:1},576:{items:2},768:{items:2.3},992:{items:3},1200:{items:4}}" class="hotdels">
                      <router-link v-for="(deal, i) in cats.hotdeal" :key="i" class="item link-dark"
                                   :to="{ name:'dealsdetail', params:{hotdeal_slug:deal.hotdeal_slug}}">
                        <v-card>
                          <div v-if="deal.hotdeal_image_url != ''">
                            <router-link :to="{ name:'dealsdetail', params:{hotdeal_slug:deal.hotdeal_slug}}" class="link-dark text-decoration-none">
                              <v-img cover :src=(url+deal.hotdeal_image_url) height="150"></v-img>
                            </router-link>
                          </div>
                          <div v-else class="text-center">
                            <router-link :to="{ name:'dealsdetail', params:{hotdeal_slug:deal.hotdeal_slug}}" class="link-dark text-decoration-none">
                              <v-img cover :src="('https://dummyimage.com/299x150/969096/3f4042.jpg&text='+deal.first_last_letter)" height="150"></v-img>
                            </router-link>
                          </div>
                          <div class="mt-2 p-2 d-flex">
                            <div class="lt w-100">
                              <p class="mb-1 fw-bold" style="max-height: 22px;overflow: hidden;">
                                <router-link :to="{ name:'dealsdetail', params:{hotdeal_slug:deal.hotdeal_slug}}" class="link-dark text-decoration-none">
                                  {{ deal.hot_deal_title }}
                                </router-link>
                              </p>
                              <div>
                                By: <router-link :to="{name: 'businessprofile', params: {id: deal.business_id, uname:deal.business_uname}}">
                                {{ deal.name }}
                              </router-link>
                              </div>
                              <!-- <div v-if="!loggedUser" class="fw-bold">Add To Wishlist
                                <v-icon>mdi-heart-outline</v-icon>
                              </div>
                              <div v-else class="fw-bold">Add To Wishlist
                                <v-icon v-if="deal.user_added_wishlist!=null" color="red" small>mdi-heart</v-icon>
                                <v-icon v-else small>mdi-heart-outline</v-icon>
                              </div> -->
                              <p class="mb-0 cred">Price:
                                <span style="color: black;">
                               ₹{{ deal.price_to }}
                            </span>
                              </p>
                              <p class="mb-0 cred">Validity: <span style="color: black;">{{ deal.date_to }}</span></p>
                              <div class="" style="max-height: 22px;overflow: hidden;">
                                <v-icon small>mdi-map-marker</v-icon>
                                {{ deal.city }}, {{ deal.state }}
                              </div>
                            </div>
                            <div class="mcontact">
                              <a :href="('https://wa.me/'+deal.mobile_phone+'?text=Enquiry from Bizlx : '+currentURL)" target="_blank">
                                <v-btn fab x-small color="success">
                                  <v-icon>mdi-whatsapp</v-icon>
                                </v-btn>
                              </a>
                              <a :href="('tel:'+ deal.mobile_phone)" target="_blank">
                                <v-btn fab x-small color="primary" class="my-2">
                                  <v-icon>mdi-phone</v-icon>
                                </v-btn>
                              </a>
                              <router-link :to="{ name:'dealsdetail', params:{hotdeal_slug:deal.hotdeal_slug}}">
                                <v-btn fab x-small color="info">
                                  <v-icon>mdi-eye-outline</v-icon></v-btn>
                              </router-link>
                            </div>
                          </div>
                        </v-card>
                      </router-link>
                    </carousel>
                    </div>
                    <div v-else class="text-center">
                      <div>No Hot Deals in {{cats.subcat_name}}</div>
                    </div>
                  </v-card-text>
                </v-card>
              </b-card-text>
            </b-tab>
          <b-tab title="Sales">
              <b-card-text>
                <v-card flat>
                  <v-card-text class="p-0">
                    <div v-if="cats.sales.length > 0">
                       <carousel :autoplay="false" :margin="30" :dots="false" :navText="['‹', '›']" :slideBy="1"
                              :responsive = "{0:{items:1},576:{items:2},768:{items:2.3},992:{items:3},1200:{items:4}}" class="hotdels">
                      <router-link v-for="(sale, i) in cats.sales" :key="i" class="item link-dark"
                                   :to="{name:'saledetail',params:{sale_slug:sale.sale_slug}}">
                        <v-card>
                          <router-link :to="{name:'saledetail',params:{sale_slug:sale.sale_slug}}">
                            <div v-if="sale.sale_imageurl != null">
                              <v-img contain :src=(url+sale.sale_imageurl) height="150"></v-img>
                            </div>
                            <div v-else>
                              <v-img :src="('https://dummyimage.com/299x150/969096/3f4042.jpg&text='+sale.first_last_letter)" cover height="150"></v-img>
                            </div>
                          </router-link>
                          <div class="mt-2 p-2 d-flex">
                            <div class="lt w-100">
                              <div class="mb-1 fw-bold">
                                <router-link :to="{name:'saledetail',params:{sale_slug:sale.sale_slug}}" class="text-decoration-none link-dark h5">
                                  {{ sale.sale_title }}
                                </router-link>
                              </div>
                              <!-- <div v-if="!loggedUser" class="fw-bold">Add To Wishlist
                                <v-icon>mdi-heart-outline</v-icon>
                              </div>
                              <div v-else class="fw-bold">Add To Wishlist
                                <v-icon v-if="sale.user_added_wishlist!=null" color="red" small>mdi-heart</v-icon>
                                <v-icon v-else small>mdi-heart-outline</v-icon>
                              </div> -->
                              <div class="cred">Sale
                                <del class="ms-1 h6" style="color: black;">₹{{ sale.normal_price }}</del>
                                <span class="ms-1 h4" style="color: black;">₹{{ sale.sale_price }}</span>
                              </div>
                              <div class="cred">Validity: <span style="color: black;">{{ sale.saledate_from }} - {{ sale.saledate_to }} </span></div>
                              <div class="address">
                                <v-icon small>mdi-map-marker</v-icon>
                                {{ sale.city }}, {{ sale.state }}
                                <router-link :to="{name:'saledetail',params:{sale_slug:sale.sale_slug}}" class="cred">Read More</router-link>
                              </div>
                            </div>
                            <div class="mcontact">
                              <a :href="('https://wa.me/'+sale.mobile_phone+'?text=Enquiry from Bizlx : '+currentURL)" target="_blank">
                                <v-btn fab x-small color="success">
                                  <v-icon>mdi-whatsapp</v-icon>
                                </v-btn>
                              </a>
                              <a :href="('tel:'+ sale.mobile_phone)" target="_blank">
                                <v-btn fab x-small color="primary" class="my-2">
                                  <v-icon>mdi-phone</v-icon>
                                </v-btn>
                              </a>
                              <router-link :to="{name:'saledetail',params:{sale_slug:sale.sale_slug}}">
                                <v-btn fab x-small color="info">
                                  <v-icon>mdi-eye-outline</v-icon></v-btn>
                              </router-link>
                            </div>
                          </div>
                        </v-card>
                      </router-link>
                    </carousel>
                    </div>
                    <div v-else class="text-center">
                      <div>No Sales in {{cats.subcat_name}}</div>
                    </div>
                  </v-card-text>
                </v-card>
              </b-card-text>
            </b-tab>
          <b-tab title="Jobs">
              <b-card-text>
                <v-card flat>
                  <v-card-text class="p-0">
                    <div v-if="cats.job.length > 0">
                       <carousel :autoplay="false" :margin="10" :dots="false" :navText="['‹', '›']" :slideBy="1"
                              :responsive = "{0:{items:1},576:{items:2},768:{items:2.3},992:{items:3},1200:{items:4}}"  class="vsearch">
                      <router-link v-for="(job, i) in cats.job" :key="i" class="item link-dark"
                                   :to="{name: 'jobdetail', params: { job_slug: job.job_slug }}">
                        <v-card>
                          <router-link :to="{name: 'jobdetail', params: { job_slug: job.job_slug }}" class="link-dark text-decoration-none">
<!--                            <v-img :src="picd+job.business_id" alt="Job Image" height="150"></v-img>-->
                            <v-img :src="picd+job.business_id" alt="Job Image" height="150"></v-img>
<!--                            <div v-if="job.mainimage != null">-->
<!--                              <v-img cover :src=(url+job.mainimage) height="150"></v-img>-->
<!--                            </div>-->
<!--                            <div v-else>-->
<!--                              <v-img cover :src="('https://dummyimage.com/299x150/969096/3f4042.jpg&text='+job.first_last_letter)" height="150"></v-img>-->
<!--                            </div>-->
                          </router-link>
                            <div class="p-2 mt-2 d-flex">
                              <div class="lt w-100">
                                <p class="mb-1 fw-bold">
                                  <router-link :to="{name: 'jobdetail', params: { job_slug: job.job_slug }}" class="link-dark text-decoration-none">
                                    {{ job.job_title }}
                                  </router-link></p>
                                <p class="mb-0">
                                  <router-link to="/jobs/detail" class="text-decoration-none cblu">
                                    {{ job.name }}
                                  </router-link>
                                </p>
                                <!-- <div v-if="!loggedUser" class="fw-bold">Add To Wishlist
                                  <v-icon>mdi-heart-outline</v-icon>
                                </div>
                                <div v-else class="fw-bold">Add To Wishlist
                                  <v-icon v-if="job.user_added_wishlist!=null" color="red" small>mdi-heart</v-icon>
                                  <v-icon v-else small>mdi-heart-outline</v-icon>
                                </div> -->
                                <div v-if="job.min_exp > 0">
                                  <div class="fs11">Experience: {{ job.min_exp }} Years</div>
                                </div>
                                <div v-else>
                                  <div class="fs11">Experience: Fresher</div>
                                </div>
                                <p class="mb-0">
                                  <span class="fw-bold">
                                     ₹{{ job.salary_from }}  a Month
                                  </span>
                                </p>
                                <div class="">
                                  <v-icon small>mdi-map-marker</v-icon>
                                  {{ job.city }}, {{ job.state }}
                                </div>
                                <router-link :to="{name: 'jobdetail', params: { job_slug: job.job_slug }}" class="text-decoration-none cred">Read More</router-link>
                              </div>
                              <div class="mcontact">
                                <a :href="('https://wa.me/'+job.mobile_phone+'?text=Enquiry from Bizlx : '+currentURL)" target="_blank">
                                  <v-btn fab x-small color="success">
                                    <v-icon>mdi-whatsapp</v-icon>
                                  </v-btn>
                                </a>
                                <a :href="('tel:'+ job.mobile_phone)" target="_blank">
                                  <v-btn fab x-small color="primary" class="my-2">
                                    <v-icon>mdi-phone</v-icon>
                                  </v-btn>
                                </a>
                                <router-link :to="{name: 'jobdetail', params: { job_slug: job.job_slug }}">
                                  <v-btn fab x-small color="info">
                                    <v-icon>mdi-eye-outline</v-icon></v-btn>
                                </router-link>
                              </div>
                            </div>
                        </v-card>
                      </router-link>
                    </carousel>
                    </div>
                    <div v-else class="text-center">
                      <div>No Jobs in {{cats.subcat_name}}</div>
                    </div>
                  </v-card-text>
                </v-card>
              </b-card-text>
            </b-tab>
          <b-tab title="Video">
              <b-card-text>
                <v-card flat>
                  <v-card-text class="p-0">
                    <div v-if="cats.video.length > 0">
                      <carousel :autoplay="false" :margin="10" :dots="false" :navText="['‹', '›']" :slideBy="1"
                              :responsive = "{0:{items:1},576:{items:2},768:{items:2.3},992:{items:3},1200:{items:4}}"  class="vsearch">
                      <div v-for="(video, i) in cats.video" :key="i" class="item">
                        <div class="item">
                          <div class="ratio ratio-16x9">
                            <iframe :src="('https://www.youtube.com/embed/'+video.youtube_id)" :title="video.video_title" allowfullscreen></iframe>
                          </div>
                        </div>
                      </div>
                    </carousel>
                    </div>
                    <div v-else class="text-center">
                      <div>No Videos in {{cats.subcat_name}}</div>
                    </div>
                  </v-card-text>
                </v-card>
              </b-card-text>
            </b-tab>
        </b-tabs>
      </div>
      <div class="position-relative mobile" v-for="cats in catslider" :key="cats.id">
        <div class="mb-4"><span class="cblu bbred h5">{{ cats.subcat_name }}</span> </div>
        <b-tabs pills active-nav-item-class="fw-bold bgred mb-2 text-white"  nav-class="fw-bold mtabs">
          <b-tab title="Info" active >
                <b-card-text>
                  <v-card flat>
                    <div v-if="cats.info.length > 0">
                      <carousel :autoplay="false" :margin="5" :items="4" :dots="false" :slideBy="1" :navText="['‹','›']"
                                :responsive = "{0:{items:1},576:{items:2},768:{items:3},1200:{items:4}}" class="hotels mobile">
                        <router-link v-for="(hotel,i) in cats.info" :key="i"
                                     class="item mitem d-flex link-dark"
                                     :to="{name: 'businessprofile', params: {id: hotel.business_id, uname:hotel.business_uname}}">
                          <router-link :to="{name: 'businessprofile', params: {id: hotel.business_id, uname:hotel.business_uname}}" class="link-dark text-decoration-none">
                            <div class="mimg" v-if="hotel.mainimage != null">
                              <v-img cover class="rounded" :src=(url+hotel.mainimage) max-width="110" height="110"></v-img>
                            </div>
                            <div v-else>
                              <v-img cover class="rounded" max-width="110" height="110" :src="('https://dummyimage.com/299x150/969096/3f4042.jpg&text='+hotel.first_last_letter)"></v-img>
                            </div>
                          </router-link>
                            <div class="mdetails">
                              <router-link :to="{name: 'businessprofile', params: {id: hotel.business_id, uname:hotel.business_uname}}" class="link-dark text-decoration-none">
                                <div class="mtitle">{{ hotel.name }}</div>
                              </router-link>
                              <div class="maddress">
                                <v-icon small>mdi-map-marker</v-icon>
                                {{hotel.city}}, {{hotel.state}}
                              </div>
                              <div class="mreviews d-flex align-items-center">
                                <v-rating dense color="warning" size="12" length="5" :value=hotel.rating readonly></v-rating><span class="badge bg-dark ms-2">{{ hotel.rating }}</span>
                              </div>
                            </div>
                            <div class="mcontact">
                                <a :href="('https://wa.me/'+hotel.mobile_phone+'?text=Enquiry from Bizlx : '+currentURL)" target="_blank">
                                  <v-btn fab x-small color="success">
                                    <v-icon>mdi-whatsapp</v-icon>
                                  </v-btn>
                              </a>
                              <a :href="('tel:'+ hotel.mobile_phone)" target="_blank">
                                <v-btn fab x-small color="primary" class="my-2">
                                  <v-icon>mdi-phone</v-icon>
                                </v-btn>
                              </a>
                              <router-link :to="{name: 'businessprofile', params: {id: hotel.business_id, uname:hotel.business_uname}}">
                                <v-btn fab x-small color="info">
                                  <v-icon>mdi-eye-outline</v-icon></v-btn>
                              </router-link>
                            </div>
                        </router-link>
                      </carousel>
                    </div>
                    <div v-else class="text-center">
                      <div>No Business in {{cats.subcat_name}}</div>
                    </div>
                  </v-card>
                </b-card-text>
            </b-tab>
          <b-tab title="Hot Deals" >
              <b-card-text>
                <v-card flat>
                  <div v-if="cats.hotdeal.length > 0">
                    <carousel :autoplay="false" :margin="5" :dots="false" :navText="['‹', '›']" :slideBy="1"
                              :responsive = "{ 0: { items: 1 }, 576: { items: 2 }, 768: { items: 3 }, 1200: { items: 4 } }" class="hotdels mobile">
                      <router-link v-for="(deal, i) in cats.hotdeal" :key="i" class="item mitem d-flex link-dark" :to="{ name:'dealsdetail', params:{hotdeal_slug:deal.hotdeal_slug}}">
                        <div class="mimg" v-if="deal.hotdeal_image_url != ''">
                          <router-link :to="{ name:'dealsdetail', params:{hotdeal_slug:deal.hotdeal_slug}}" class="link-dark text-decoration-none">
                            <v-img cover class="rounded" :src=(url+deal.hotdeal_image_url) max-width="110" height="110"></v-img>
                          </router-link>
                          </div>
                          <div v-else>
                            <router-link :to="{ name:'dealsdetail', params:{hotdeal_slug:deal.hotdeal_slug}}" class="link-dark text-decoration-none">
                              <v-img cover class="rounded" max-width="110" height="110" :src="('https://dummyimage.com/299x150/969096/3f4042.jpg&text='+deal.first_last_letter)"></v-img>
                            </router-link>
                          </div>
                            <div class="mdetails p-1">
                              <router-link :to="{ name:'dealsdetail', params:{hotdeal_slug:deal.hotdeal_slug}}" class="link-dark text-decoration-none mtitle fs12">
                                  {{ deal.hot_deal_title }}
                              </router-link>
                              <div class="fs12">
                                By: <router-link :to="{name: 'businessprofile', params: {id: deal.business_id, uname:deal.business_uname}}">
                                {{ deal.name }}
                                </router-link>
                              </div>
                              <p class="mb-0 cred fs12">Price:
                                <span style="color: black;">
                                   ₹{{ deal.price_to }}
                                </span>
                              </p>
                              <p class="mb-0 cred fs12">Validity: <span style="color: black;">{{ deal.date_to }}</span></p>
                              <div class="fs12">
                                <v-icon small>mdi-map-marker</v-icon>
                                {{ deal.city }}, {{ deal.state }}
                              </div>
                            </div>
                            <div class="mcontact fs12">
                              <a :href="('https://wa.me/'+deal.mobile_phone+'?text=Enquiry from Bizlx : '+currentURL)" target="_blank">
                                <v-btn fab x-small color="success">
                                  <v-icon>mdi-whatsapp</v-icon>
                                </v-btn>
                              </a>
                              <a :href="('tel:'+ deal.mobile_phone)" target="_blank">
                                <v-btn fab x-small color="primary" class="my-2">
                                  <v-icon>mdi-phone</v-icon>
                                </v-btn>
                              </a>
                              <router-link :to="{ name:'dealsdetail', params:{hotdeal_slug:deal.hotdeal_slug}}">
                                <v-btn fab x-small color="info">
                                  <v-icon>mdi-eye-outline</v-icon></v-btn>
                              </router-link>
                            </div>
                      </router-link>
                    </carousel>
                  </div>
                  <div v-else class="text-center">
                    <div>No Hot Deals in {{cats.subcat_name}}</div>
                  </div>
                </v-card>
              </b-card-text>
            </b-tab>
          <b-tab title="Sales">
            <b-card-text>
              <v-card flat>
                <v-card-text class="p-0">
                  <div v-if="cats.sales.length > 0">
                    <carousel :autoplay="false" :margin="5" :dots="false" :navText="['‹', '›']" :slideBy="1"
                              :responsive = "{ 0: { items: 1 }, 576: { items: 2 }, 768: { items: 3 }, 1200: { items: 4 } }" class="hotdels">
                      <router-link v-for="(sale, i) in cats.sales" :key="i" class="item mitem d-flex link-dark" :to="{name:'saledetail',params:{sale_slug:sale.sale_slug}}">
                        <v-card>
                          <router-link :to="{name:'saledetail',params:{sale_slug:sale.sale_slug}}">
                            <div v-if="sale.sale_imageurl != null">
                              <v-img cover class="rounded" :src=(url+sale.sale_imageurl) max-width="110" height="110"></v-img>
                            </div>
                            <div v-else>
                              <v-img cover class="rounded" :src="('https://dummyimage.com/400x350/fd0606/fff&text='+sale.first_last_letter)" max-width="110" height="110"></v-img>
                            </div>
                          </router-link>
                          <!-- <div class="mt-2 p-2 d-flex"> -->
                            <div class="mdetails p-1">
                              <router-link :to="{name:'saledetail',params:{sale_slug:sale.sale_slug}}" class="text-decoration-none link-dark mtitle fs12">
                                  {{ sale.sale_title }}
                              </router-link>
                              <div class="fs12">
                                By: <router-link :to="{name: 'businessprofile', params: {id: sale.business_id, uname:sale.business_uname}}">
                                {{ sale.name }}
                                </router-link>
                              </div>
                              <div class="cred fs12">Sale
                                <del class="ms-1 h6" style="color: black;">₹{{ sale.normal_price }}</del>
                                <span class="ms-1 h4" style="color: black;">₹{{ sale.sale_price }}</span>
                              </div>
                              <div class="cred fs12">Validity: <span style="color: black;">{{ sale.saledate_from }} - {{ sale.saledate_to }} </span></div>
                              <div class="address fs12">
                                <v-icon small>mdi-map-marker</v-icon>
                                {{ sale.city }}, {{ sale.state }}
                                <router-link :to="{name:'saledetail',params:{sale_slug:sale.sale_slug}}" class="cred">Read More</router-link>
                              </div>
                            </div>
                            <div class="mcontact fs12">
                              <a :href="('https://wa.me/'+sale.mobile_phone+'?text=Enquiry from Bizlx : '+currentURL)" target="_blank">
                                <v-btn fab x-small color="success">
                                  <v-icon>mdi-whatsapp</v-icon>
                                </v-btn>
                              </a>
                              <a :href="('tel:'+ sale.mobile_phone)" target="_blank">
                                <v-btn fab x-small color="primary" class="my-2">
                                  <v-icon>mdi-phone</v-icon>
                                </v-btn>
                              </a>
                              <router-link :to="{name:'saledetail',params:{sale_slug:sale.sale_slug}}">
                                <v-btn fab x-small color="info">
                                  <v-icon>mdi-eye-outline</v-icon></v-btn>
                              </router-link>
                            </div>
                          <!-- </div> -->
                        </v-card>
                      </router-link>
                    </carousel>
                  </div>
                  <div v-else class="text-center">
                    <div class="fs14">No Sales in {{cats.subcat_name}}</div>
                  </div>
                </v-card-text>
              </v-card>
            </b-card-text>
          </b-tab>
          <b-tab title="Jobs" >
              <b-card-text>
                <v-card flat>
                  <div v-if="cats.job.length > 0">
                    <carousel :autoplay="false" :margin="5" :dots="false" :navText="['‹', '›']" :slideBy="1"
                              :responsive = "{ 0: { items: 1 }, 576: { items: 2 }, 768: { items: 3 }, 1200: { items: 4 } }"  class="vsearch">
                      <router-link v-for="(job, i) in cats.job" :key="i" class="item mitem d-flex link-dark"
                                   :to="{name: 'jobdetail', params: { job_slug: job.job_slug }}">
                          <!-- <v-card> -->
                            <div class="mimg" v-if="job.mainimage != null">
                              <router-link :to="{name: 'jobdetail', params: { job_slug: job.job_slug }}" class="link-dark text-decoration-none">
                                <v-img :src="picm+job.business_id" cover class="rounded"  max-width="110" alt="Job Image" height="110"></v-img>
<!--                                <v-img cover class="rounded" max-width="110" height="110" :src=(url+job.mainimage) ></v-img>-->
                              </router-link>
                            </div>
                            <div v-else>
                              <router-link :to="{name: 'jobdetail', params: { job_slug: job.job_slug }}" class="link-dark text-decoration-none">
                                <v-img :src="picm+job.business_id" cover class="rounded"  max-width="110" alt="Job Image" height="110"></v-img>
<!--                                <v-img cover class="rounded" max-width="110" height="110" :src="('https://dummyimage.com/299x150/969096/3f4042.jpg&text='+job.first_last_letter)" ></v-img>-->
                              </router-link>
                            </div>
                            <div class="mdetails p-1">
                              <router-link to="/jobs/detail" class="link-dark text-decoration-none mtitle fs12">
                                  {{ job.job_title }}
                              </router-link>
                              <p class="mb-0 fs12">
                                <router-link :to="{name: 'jobdetail', params: { job_slug: job.job_slug }}" class="text-decoration-none cblu">
                                  {{ job.name }}
                                </router-link>
                              </p>
                              <div class="fs12" v-if="job.min_exp > 0">
                                <div class="fs11">Experience: {{ job.min_exp }} Years</div>
                              </div>
                              <div class="fs12" v-else>
                                <div class="fs11">Experience: Fresher</div>
                              </div>
                              <p class="mb-0 fs12">
                                  <span class="fw-bold">
                                    {{ job.salary_from }}  a Month
                                  </span>
                              </p>
                              <div class="fs12">
                                <v-icon small>mdi-map-marker</v-icon>
                                {{ job.city }}, {{ job.state }}
                              </div>
                              <router-link :to="{name: 'jobdetail', params: { job_slug: job.job_slug }}" class="text-decoration-none cred fs12">Read More</router-link>
                            </div>

                            <div class="mcontact fs12">
                              <a :href="('https://wa.me/'+job.mobile_phone+'?text=Enquiry from Bizlx : '+currentURL)" target="_blank">
                                <v-btn fab x-small color="success">
                                  <v-icon>mdi-whatsapp</v-icon>
                                </v-btn>
                              </a>
                              <a :href="('tel:'+ job.mobile_phone)" target="_blank">
                                <v-btn fab x-small color="primary" class="my-2">
                                  <v-icon>mdi-phone</v-icon>
                                </v-btn>
                              </a>
                              <router-link :to="{name: 'jobdetail', params: { job_slug: job.job_slug }}">
                                <v-btn fab x-small color="info">
                                  <v-icon>mdi-eye-outline</v-icon></v-btn>
                              </router-link>
                            </div>
                        <!-- </v-card> -->
                      </router-link>
                    </carousel>
                  </div>
                  <div v-else class="text-center">
                    <div>No Jobs in {{cats.subcat_name}}</div>
                  </div>
                </v-card>
              </b-card-text>
            </b-tab>
          <b-tab title="Video" >
              <b-card-text>
                <v-card flat>
                  <div v-if="cats.video.length > 0">
                    <carousel :autoplay="false" :margin="10" :dots="false" :navText="['‹', '›']" :slideBy="1"
                            :responsive = "{ 0: { items: 1 }, 576: { items: 2 }, 768: { items: 3 }, 1200: { items: 4 } }"  class="vsearch">
                    <div v-for="(video, i) in cats.video" :key="i" class="item">
                      <div class="item">
                        <div class="ratio ratio-16x9">
                          <iframe :src="('https://www.youtube.com/embed/'+video.youtube_id)" :title="video.video_title" allowfullscreen></iframe>
                        </div>
                      </div>
                    </div>
                  </carousel>
                  </div>
                  <div v-else class="text-center">
                    <div>No Videos in {{cats.subcat_name}}</div>
                  </div>
                </v-card>
              </b-card-text>
            </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>
<script>
import carousel from "vue-owl-carousel";
import axios from "axios";
export default {
  name: "HomeHotels",

  components:{carousel},
  data (){
    return{
      itemsPerRow:2,
      catslider: [],
      picd:'https://source.unsplash.com/random/400x200?sig=',
      picm:'https://source.unsplash.com/random/200x200?sig=',
      url:'https://bizlx.itechvision.in',
      loggedUser: [],
      currentURL:'',
    }
  },
  mounted() {
    this.loggedUser = this.$store.state.userData;
    this.getSlider();
    this.currentURL = this.getCurrentURL();
  },
  methods: {
    getCurrentURL() {
      return window.location.href;
    },
    getSlider() {
      axios.get('home/catsliders'+'/'+this.loggedUser.id)
          .then((resp) => {
            this.catslider = resp.data.catsliders;
          });
    },
  },
  
  // computed: {
  //   catGroups () {
  //     return Array.from(Array(Math.ceil(this.catslider.length / this.itemsPerRow)).keys())
  //   }
  // },
}
</script>


<style scoped>
.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
	display: none;
	visibility: hidden;
} 
.minwid.v-tab.v-tab--active {
	background: red;
	color: #fff;
  border-radius: 5px;
}
.v-tabs-slider {
    background-color: azure !important;
    height: 0% !important;
    width: 0% !important;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active), .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon, .theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn, .theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
    color: #000;
}
.minwid.v-tab {
  margin-right: 4px !important;
	min-width: 70px !important;
	padding: 0 5px !important;
  text-transform: capitalize;
  height: 28px !important;
  font-weight: bold !important;
  letter-spacing: initial;
}
.minwid.v-tab:hover {
  background: #fd0606 !important;
  color: #fff !important;
  border-radius: 5px;
  font-weight: bold !important;
  letter-spacing: initial;
}

.item.mitem {
  box-shadow: 0 6px 10px -6px rgba(0,0,0,0.5);
  margin-bottom: 15px;
  background: #f1f2f380;
  padding: 7px;
}
.mdetails {
  padding: 7px 0 0 7px;
}
.mimg {
  width: 110px;
}
.fs14{font-size: 14px;}
.fs12{font-size: 12px;}
.mtitle {
  font-weight: 600;
}
.maddress {
  font-size: 12px;
}
.mdetails {
  width: 100%;
}
.mcontact {
  width: 42px;
}
.heart-filled {
  color: red;
}
.heart-unfilled {
  color: grey;
}
@media(max-width: 576px){
  .mhead {
    position: absolute;
    left: 5px;
    top: 4px;
  }
}
</style>