<template>
  <v-container>
    <v-row class="py-md-5">
      <div class="d-flex justify-content-between">
        <h5 class="home-service cblu">
          <span class="bbred">Popular</span>
        </h5>
        <router-link to="/category/all" class="allcat cblu text-end h5">All</router-link>
      </div>
      <v-col>
        <home-populars></home-populars>
      </v-col>
    </v-row>
    <v-row class="py-md-5">
      <h5 class="home-service cblu">
        <span class="bbred">Top Ads</span>
      </h5>
      <v-col>
        <home-ads></home-ads>
      </v-col>
    </v-row>
    <v-row class="py-md-5">
      <div class="d-flex justify-content-between">
        <h5 class="home-service cblu">
          <span class="bbred">Jobs</span>
        </h5>
        <router-link to="/jobs/all-cities" class="alljobs cblu text-end h5">All</router-link>
      </div>

      <v-col>
        <home-jobs></home-jobs>
      </v-col>
    </v-row>
    <v-row class="py-md-5">
      <h5 class="home-service cblu">
        <span class="bbred">City Deals</span>
      </h5>
      <v-col>
        <home-citydeals></home-citydeals>
      </v-col>
    </v-row>
    <v-row class="py-md-5">
      <v-col class="px-0 px-md-3 py-0">
        <HomeHotels/>
      </v-col>
    </v-row>
<!--    <v-row class="py-md-5">-->
<!--      <v-col class="px-0 px-md-3">-->
<!--        <HomeJewellers/>-->
<!--      </v-col>-->
<!--    </v-row>-->
    <!-- <v-row class="py-md-5">
      <h5 class="home-service cblu">
        <span class="bbred">City Deals</span>
      </h5>
      <v-col>
        <home-citydeals></home-citydeals>
      </v-col>
    </v-row> -->


  </v-container>
</template>

<script>
import HomeCitydeals from "@/views/slider/HomeCitydeals";
import HomePopulars from "@/views/slider/HomePopulars";
import HomeAds from "@/views/slider/HomeAds";
import HomeJobs from "@/views/slider/HomeJobs";
import HomeHotels from "@/views/slider/HomeHotels";
// import HomeJewellers from "@/views/slider/HomeJewellers";
export default {
  name: 'HomeView',
  components: {/*HomeJewellers,*/ HomeHotels, HomeJobs, HomeAds, HomePopulars, HomeCitydeals},
  metaInfo: {
    title: 'Home',
  meta: [
    {
      property: 'og:title',
      content: 'Bizlx.com™   Search'
    },
    {property: 'og:url', content: 'https://bizlx.com'},
    {
      property: 'og:image',
      content: 'https://bizlx.com/media/home-meta-banner/1/1_1641323279.8188bizlx-business-promotion-app-website3.png'
    },
    {
      property: 'og:keywords',
      content: 'Bizlx,bizlx hot deals,Bizlx hotel deals,Bizlx daily deals website,India Daily Hot Deals Website,resort deals bizlx,Bizlx Founder Rajneesh Sharma,Products,Professional,Professionals,Local Hot Deals,promotion website,cottages listed on bizlx,Bizlx discounts,Leading Hotels in Bizlx'
    },
    {
      property: 'og:description',
      content: 'Bizlx.com™ is India&#039;s daily local hot deals, sales, and latest information website of shops and stores. Search hotels, shops,garments, furniture, jewelry, automobiles, professionals, mobile phones, restaurants with reviews &amp;amp; discounts on shopping &amp;amp; product videos everyday. Bizlx provides local, accurate &amp;amp; verified business information to customers with regular updates.'
    },
  ],
},
  data() {
    return {}
  }
}
</script>
<style scoped>
a.allcate.cred.text-end {
  position: absolute;
  bottom: 43px;
  right: 0;
  font-weight: bold;
  z-index: 2;
  left: auto;
  width: 50px;
  }
a.alljobse.cred.text-end {
  position: absolute;
  bottom: 43px;
  right: 15px;
  font-weight: bold;
  z-index: 2;
  left: auto;
  width: 50px;
  }
@media (max-width: 576px){
  a.allcat.cred.text-end {
    bottom: 20px;
    }
  a.alljobs.cred.text-end {
    bottom: 20px;
    }
  }
</style>
