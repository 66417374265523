<template>
  <div>
    <div class="mobile">
      <carousel v-if="ads.length > 0" :autoplay="false" :margin="10" :items="4" :dots="false" :slideBy="1"
              :navText="['‹','›']"
              :responsive = "{0:{items:2},576:{items:2},768:{items:3},1200:{items:4}}"
              :loop="true">
      <div v-for="ad in ads" :key="ad.id" class="cole">
        <router-link :to="{name: 'businessprofile', params: {id: ad.business_id, uname:ad.business_uname}}">
          <v-card>
            <v-img
            :src=(url+ad.ad_img_url) height="160" :alt=ad.business_name
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,0.01), rgba(0,0,0,.6)"
            >
              <div class="p-2">
                <div class="adtitle">{{ ad.business_name }}</div>
                <div class="loc">
                  <v-icon small>mdi-map-marker</v-icon>
                   {{ ad.city }}, {{ ad.state }}
                </div>
              </div>
            </v-img>
          </v-card>
          <!-- <div class="overlay"></div>
            <img :src=(url+ad.ad_img_url) height="160" :alt=ad.business_name>
            <p class="adtitle">
              {{ad.business_name}}
            </p>
            <div class="loc">
              <v-icon small>mdi-map-marker</v-icon>
              {{ ad.state }} {{ ad.city }}
            </div> -->
        </router-link>
      </div>
    </carousel>
    </div>
    <div class="desktop">
      <carousel v-if="ads.length > 0" :autoplay="false" :margin="30" :items="4" :dots="false" :slideBy="1"
              :navText="['‹','›']"
              :responsive = "{0:{items:1},576:{items:2},768:{items:3},1200:{items:4}}"
              :loop="true">
      <div v-for="ad in ads" :key="ad.id" class="cole">
        <router-link :to="{name: 'businessprofile', params: {id: ad.business_id, uname:ad.business_uname}}">
          <v-card>
            <v-img
            :src=(url+ad.ad_img_url) height="160" :alt=ad.business_name
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,0.01), rgba(0,0,0,.6)"
            >
              <div class="p-2">
                <div class="adtitle">{{ ad.business_name }}</div>
                <div class="loc">
                  <v-icon small>mdi-map-marker</v-icon>
                  {{ ad.state }} {{ ad.city }}
                </div>
              </div>
            </v-img>
          </v-card>
        </router-link>
      </div>
    </carousel>
    </div>
  </div>
</template>

<script>
import carousel from 'vue-owl-carousel';
import axios from "axios";
export default {
  name: "HomeAds",
  components:{carousel},
  data (){
    return{
      url:'https://bizlx.itechvision.in',
      // url:'http://127.0.0.1:8000',
      ads:[],
    }
  },
  created() {
    this.allTopads();
  },
  methods:{
    allTopads(){
      axios.get('home/topads')
          .then((resp)=>{
            this.ads = resp.data.topads;
          });
    }
  }
}
</script>

<style scoped>
.overlay {
  background: #00000054;
  position: absolute;
  height: 100%;
  width: 100%;
}
img.img-fluid.top-listing {
  width: 100%;
  height: 250px;
  object-fit: cover;
}
 div.adtitle {
  font-weight: bold;
  font-size: 20px;
  max-height: 30px;
  overflow: hidden;
}
.desktop div.loc {
  font-size: 12px;
}
.mobile div.adtitle {
  font-weight: bold;
  font-size: 14px;
  max-height: 21px;
  overflow: hidden;
}
.mobile div.loc {

  font-size: 11px;
}

</style>